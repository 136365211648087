import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

export default function SHEQ() {
  return (
    <Layout>
      <Seo
        title="SHEQ"
        description="Our Certified Integrated Management System containing ISO 9001, ISO 45001 and IRATA Codes of Practice encompassing Safety, Health, Environment and Quality Objectives, Targets, Accountibility, Responsibilities and Continuous Improvement."
        pathname="/sheq"
      />
      <section class="hero hero--sheq px-4 bg-brand-blue flex items-center relative">
        <StaticImage
          className="absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full object-cover"
          src="../images/hero-sheq-v2.jpg"
          alt="Contact Us"
          placeholder="blurred"
        />
        <div class="container mx-auto relative z-10">
          <div class="hero__content text-center">
            <h1 class="text-white pb-4 relative">
              SHEQ
              <span className="border-b-2 border-brand-orange inline-block h-1 w-24 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
            </h1>
          </div>
        </div>
      </section>
      <section className="px-4 py-24" id="why-asp">
        <p className="text-center mb-12 lg:text-lg sm:w-3/4 lg:w-1/2 sm:mx-auto text-brand-blue font-semibold">
          Our Certified Integrated Management System containing ISO 9001, ISO
          45001 and IRATA Codes of Practice encompassing Safety, Health,
          Environment and Quality Objectives, Targets, Accountibility,
          Responsibilities and Continuous Improvement.
        </p>
        <div className="container mx-auto">
          <div className="about-why__item mb-12 md:w-3/4 md:mx-auto relative">
            <div className="text-center sm:absolute sm:-top-2 sm:right-0 sm:w-28 sm:h-28 bg-white sm:z-20">
              <div className="opacity-0 sm:opacity-100 absolute top-0 left-0 border-t border-l border-b border-gray-400 w-12 sm:h-full"></div>
              <StaticImage
                className="inline-block w-32 sm:w-auto mb-2 sm:mb-0"
                src="../images/icon-sheq-safety.png"
                alt="Safety"
                placeholder="blurred"
              />
            </div>
            <span className="opacity-0 sm:opacity-100 border-t border-gray-400 block h-1 w-full absolute top-3 left-0"></span>
            <h5 className="text-brand-blue font-semibold mb-4 bg-white inline-block pr-4 relative z-10 tracking-widest">
              SAFETY
            </h5>
            <p className="mb-6 sm:w-3/4">
              A Safe Project starts and ﬁnishes with a correct understanding of
              the associated risks of any work scope performed by CAST. Our goal
              is Zero Harm; we achieve this by applying Risk Based Thinking,
              Suitable Training, Adequate Resource Allocation and engagement
              with Employees and Contractors.
            </p>
          </div>
          <div className="about-why__item mb-12 md:w-3/4 md:mx-auto relative">
            <div className="text-center sm:absolute sm:-top-2 sm:right-0 sm:w-28 sm:h-28 bg-white sm:z-20">
              <div className="opacity-0 sm:opacity-100 absolute top-0 left-0 border-t border-l border-b border-gray-400 w-12 sm:h-full"></div>
              <StaticImage
                className="inline-block w-32 sm:w-auto mb-2 sm:mb-0"
                src="../images/icon-sheq-health.png"
                alt="Health"
                placeholder="blurred"
              />
            </div>
            <span className="opacity-0 sm:opacity-100 border-t border-gray-400 block h-1 w-full absolute top-3 left-0"></span>
            <h5 className="text-brand-blue font-semibold mb-4 bg-white inline-block pr-4 relative z-10 tracking-widest">
              HEALTH
            </h5>
            <p className="mb-6 sm:w-3/4">
              Employee and Contractor Health is a critical focus to ensure safe
              production. We strive to provide good working conditions and
              encourage healthy lifestyles to bring about a safer and more
              productive work environment.
            </p>
          </div>
          <div className="about-why__item mb-12 md:w-3/4 md:mx-auto relative">
            <div className="text-center sm:absolute sm:-top-2 sm:right-0 sm:w-28 sm:h-28 bg-white sm:z-20">
              <div className="opacity-0 sm:opacity-100 absolute top-0 left-0 border-t border-l border-b border-gray-400 w-12 sm:h-full"></div>
              <StaticImage
                className="inline-block w-32 sm:w-auto mb-2 sm:mb-0"
                src="../images/icon-sheq-environment.png"
                alt="Environment"
                placeholder="blurred"
              />
            </div>
            <span className="opacity-0 sm:opacity-100 border-t border-gray-400 block h-1 w-full absolute top-3 left-0"></span>
            <h5 className="text-brand-blue font-semibold mb-4 bg-white inline-block pr-4 relative z-10 tracking-widest">
              ENVIRONMENT
            </h5>
            <p className="mb-6 sm:w-3/4">
              Responsibly use and protect our limited natural resources and
              materials to reduce waste and work towards sustainability for the
              beneﬁt of future generations. Carbon footprint reduction has
              created opportunities to re-think the way we do business; and
              engage with our customers who have an aligned environmental ethos.
            </p>
          </div>
          <div className="about-why__item mb-12 md:w-3/4 md:mx-auto relative">
            <div className="text-center sm:absolute sm:-top-2 sm:right-0 sm:w-28 sm:h-28 bg-white sm:z-20">
              <div className="opacity-0 sm:opacity-100 absolute top-0 left-0 border-t border-l border-b border-gray-400 w-12 sm:h-full"></div>
              <StaticImage
                className="inline-block w-32 sm:w-auto mb-2 sm:mb-0"
                src="../images/icon-sheq-quality.png"
                alt="Quality"
                placeholder="blurred"
              />
            </div>
            <span className="opacity-0 sm:opacity-100 border-t border-gray-400 block h-1 w-full absolute top-3 left-0"></span>
            <h5 className="text-brand-blue font-semibold mb-4 bg-white inline-block pr-4 relative z-10 tracking-widest">
              QUALITY
            </h5>
            <p className="mb-6 sm:w-3/4">
              Early customer engagement assists in an accurate understanding of
              needs and expectations of both parties to produce a quality
              service delivered safely, on time and within budget through
              measurable objectives.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
